<template>
  <v-container>
    <!-- BOC:[breadcrumbs] -->
    <ABreadcrumb :items="breadcrumbs"></ABreadcrumb>
    <!-- EOC -->
    <!-- BOC:[error] -->
    <AError
      v-if="editApi.isError"
      :api="editApi"
      :callbackReset="() => (editApi.isError = false)"
    ></AError>
    <!-- EOC -->

    <v-card
      class="mx-auto pa-3"
      max-width="800"
      outlined
      min-height="200"
    >
      <v-card-title>Setup Account</v-card-title>
      <div class="ml-4">Welcome, {{auth.User.name}}.</div>
      <v-stepper elevation="0" value="2">
        <v-stepper-header>
          <v-stepper-step step="1" :complete="true">Verify Mobile</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="2" :complete="false">Set Password</v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step step="3" :complete="false">Done</v-stepper-step>
        </v-stepper-header>
      </v-stepper>
      <v-card-subtitle><b>Set Password</b></v-card-subtitle>
      <div class="d-flex align-center justify-space-around">
        <div class="form-content text-center">
          <v-form ref="formPassword" v-model="valid" lazy-validation>
            <div class="text-center pt-3">
              <div class="text-center pt-1">
                <TextField
                  :value="newPassword"
                  label="New Password"
                  :rules="newPasswordRules"
                  type="password"
                  @input="newPassword = $event"
                ></TextField>
              </div>
              <div class="text-center pt-1">
                <TextField
                  :value="confirmPassword"
                  label="Confirm Password"
                  :rules="confirmPasswordRules"
                  type="password"
                  @input="confirmPassword = $event"
                ></TextField>
              </div>
            </div>
            <div class="text-center pt-1 mb-2">
              <v-btn
                block
                :loading="editApi.isLoading"
                rounded
                color="primary"
                dark
                @click="submit()"
              >
                Next
              </v-btn>
            </div>
            <div class="text-center mb-3">
              <v-btn
                block
                rounded
                color="primary"
                outlined
                @click="logout()"
              >
                Log Out
              </v-btn>
            </div>
          </v-form>
        </div>
      </div>
    </v-card>
  </v-container>
</template>
    
    <script>
import { mapState } from "vuex";
import TextField from "@/components/Form/TextField.vue";
export default {
  components: {
    TextField,
  },
  computed: {
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmPassword || "Passwords do not match.";
    },
    ...mapState({
      auth: (state) => state.auth.data,
    }),
  },
  props: [
    //
  ],
  data: () => ({
    //BOC:[breadcrumbs]
    breadcrumbs: [],
    //EOC
    valid: true,
    newPassword: null,
    confirmPassword: null,
    confirmPasswordRules: [],
    newPasswordRules: [],
    data: {},
    //BOC:[api]
    editApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
  }),
  created() {
    this.editApi.url =
      this.$api.servers.sso +
      "/api/v1/" +
      this.$_getLocale() +
      "/educator/account/changePassword";

    this.editApi.callbackReset = () => {
      this.editApi.isLoading = true;
      this.editApi.isError = false;
    };

    this.editApi.callbackError = (e) => {
      this.editApi.isLoading = false;
      this.editApi.isError = true;
      this.editApi.error = e;
    };

    this.editApi.callbackSuccess = () => {
      this.editApi.isLoading = false;
      var authData = this.$_.cloneDeep(this.auth);
      authData.Account.isChangedPassword = true;
      this.$store.commit("updateAuth", authData);
      this.$router.push({ name: "PageEducatorDashboard" });
      //this.$router.push({ name: "PageEducatorOnboardTelegram" });
    };
  },
  mounted() {},
  watch: {
    newPassword: function () {
      this.newPasswordRules = [];
    },
    confirmPassword: function () {
      this.confirmPasswordRules = [];
    },
  },
  methods: {
    submit() {
      this.newPasswordRules = [
        (v) => !!v || "Cannot be blank",
        (v) => (v && v.length >= 8) || "Password must have at least 8 characters.",
      ];
      this.confirmPasswordRules = [
        (v) => !!v || "Cannot be blank",
        this.passwordConfirmationRule,
      ];
      let self = this;
      setTimeout(function () {
        if (self.$refs.formPassword.validate()) {
          self.editApi.params = {
            password: self.newPassword,
          };
          self.$api.fetch(self.editApi);
        } else {
          self.editApi.callbackError("Please check the form.");
        }
      });
    },
    logout() {
      //
    },
  },
};
</script>
<style scoped>
.menu-item {
  border: 1px solid black;
  margin: 3px;
}
.form-content {
  width: 300px !important;
}
</style>